<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'home'"
    :toolbarColor="'primary'"
    :headerClass="'fixed-home'"
    :pageDefaultBackLink="'/home'"
    :pageTitle="lang.futures ?? 'Futures'"
    :contentColor="'primary'"
    :selectLanguage="true"
    @content="setIonContent"
  >
    <!-- Mininghamster -->
    <template v-slot:actions-end>
      <ion-button router-link="/settings" id="futures-settings-btn" v-if="!browser">
        <ion-icon slot="icon-only" :icon="settingsSharp"></ion-icon>
      </ion-button>
    </template>

    <template v-slot:segment>
      <ion-segment
        v-model="segment"
        class="segment"
        @ionChange="segmentChanged($event)"
        ref="segment"
        scrollable
      >
        <ion-segment-button
          :value="'0'"
          layout="icon-start"
          id="futures-segment-button-0"
        >
          <ion-label>{{ lang.all ?? "All" }}</ion-label>
          <!-- <ion-icon :icon="eye"></ion-icon> -->
        </ion-segment-button>

        <ion-segment-button
          :value="'1'"
          layout="icon-start"
          id="futures-segment-button-1"
        >
          <ion-label :class="{ notify: badges.futures }">{{
            lang.three_to_ten ?? "3-10x"
          }}</ion-label>
          <!-- <ion-icon :icon="eye"></ion-icon> -->
        </ion-segment-button>

        <ion-segment-button
          :value="'2'"
          layout="icon-start"
          id="futures-segment-button-2"
        >
          <ion-label :class="{ notify: badges.high_leverage }">{{
            lang.high_leverage ?? "High Leverage"
          }}</ion-label>
          <!-- <ion-icon :icon="eye"></ion-icon> -->
        </ion-segment-button>

        <ion-segment-button
          :value="'3'"
          layout="icon-start"
          id="futures-segment-button-3"
        >
          <ion-label>{{ lang.pins ?? "Pins" }}</ion-label>
          <!-- <ion-icon :icon="eye"></ion-icon> -->
        </ion-segment-button>
      </ion-segment>
    </template>

    <template v-slot:ion-content>
      <div
        :class="'page-container' + (minimizeHeader ? ' header-minimized' : '')"
      >
        <ion-slides
          :options="slideOpts"
          @ionSlideDidChange="slideChanged($event)"
          ref="slides"
        >
          <ion-slide class="ion-slide">
            <banner
              type="danger"
              v-if="
                lists['all_futures'].initialized &&
                !notificationPermission &&
                !browser
              "
              :title="lang.enable_notifications ?? 'Enable notifications'"
              :actions="banner_actions.notification_permission"
              :text="
                lang.no_notifications_banner_text ??
                'Notifications turned off, you currently cannot receive new signals and target updates when the app is closed.'
              "
              name="notification_permission_banner"
            ></banner>

            <banner
              v-if="
                lists['all_futures'].initialized &&
                !store.state.banners['notification_permission_banner'] &&
                !browser
              "
              :type="'danger'"
              :title="lang.trade_with_cation ?? 'Trade with caution'"
              :text="
                lang.trading_futures_involves ??
                'Trading futures involves a serious risk of loss. Our app will not be responsible'
              "
              name="all_futures"
            ></banner>

            <!-- App install call to action banner for web only -->
            <download-banner
              v-if="lists['all_futures'].initialized && browser"
              type="info"
              :title="lang.change ?? 'Profit more on the Uptrade app'"
              :text="
                lang.change ??
                'Receive instant notifications for new signals, buy and sell at the right moment, add signals to favorites, and more when you use our signals via the Uptrade app.'
              "
              name="cta_banner_futures"
            ></download-banner>

            <div class="all-futures-scroll parent-scroll ion-padding">
              <all-futures
                ref="all_futures"
                @interfaces="getInterfaces('all_futures', $event)"
                @updateToggle="newSignalToggle"
                :updateProp="newSignal"
                @initialized="listInitialized('all_futures')"
              ></all-futures>
            </div>
          </ion-slide>

          <ion-slide class="ion-slide">
            <div class="futures-scroll parent-scroll ion-padding">
              <low-leverage
                ref="futures"
                @interfaces="getInterfaces('futures', $event)"
                @updateToggle="newSignalToggle"
                :updateProp="newSignal"
              ></low-leverage>
            </div>
          </ion-slide>

          <ion-slide class="ion-slide">
            <div class="high-leverage-scroll parent-scroll ion-padding">
              <high-leverage
                ref="high_leverage"
                @interfaces="getInterfaces('high_leverage', $event)"
                @updateToggle="newSignalToggle"
                :updateProp="newSignal"
              ></high-leverage>
            </div>
          </ion-slide>

          <ion-slide class="ion-slide">
            <div class="pinned-futures-scroll parent-scroll ion-padding">
              <pinned
                ref="pinned"
                @interfaces="getInterfaces('pinned', $event)"
                v-if="!browser"
              ></pinned>

              <pinned-locked v-else></pinned-locked>
            </div>
          </ion-slide>
        </ion-slides>

        <transition name="fade">
          <ion-fab
            class="home-ion-fab scroll-up"
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            v-if="newSignal"
            v-on:click="softReset"
          >
            <ion-fab-button class="ion-fab-button">
              <ion-icon :icon="arrowUp"></ion-icon>
            </ion-fab-button>
          </ion-fab>
        </transition>
      </div>
    </template>
  </base-layout-2>
</template>

<script>
// import {defineAsyncComponent} from "vue";
import {
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonSlides,
  IonSlide,
} from "@ionic/vue";
import { settingsSharp, search, arrowUp, cloudDownload } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
// import SignalFilterBtn from "@/views/components/cat-3/SignalFilterBtn.vue";
import Banner from "../components/cat-3/Banner.vue";
import { Plugins } from "@capacitor/core";
import LowLeverage from "../components/cat-3/Futures/LowLeverage.vue";
import AllVue from "../components/cat-3/Futures/All.vue";
import HighLeverage from "../components/cat-3/Futures/HighLeverage.vue";
import Pinned from "../components/cat-3/Futures/PinnedFutures.vue";
import PinnedLocked from '../components/cat-3/PinnedLocked.vue';
import DownloadBanner from '../components/cat-3/DownloadBanner.vue';
const { NativeHelper } = Plugins;

export default {
  inject: ["store"],
  data() {
    return {
      settingsSharp,
      search,
      arrowUp,
      created: false,
      newSignal: false,
      newSell: false,
      listActionTrigger: false,
      listActionHandler: null,
      filterModal: null,
      ready: false,
      segment: "0",
      minimizeHeader: false, //Flag to toggle between minimized and maximized header state
      lastScrollTop: 0,
      slideOpts: {
        initialSlide: 0,
        slidesPerView: 1,
        speed: 200,
      },
      lists: {
        all_futures: {
          initialized: false,
        },
      },
      content: null,
      badges: {
        //Flags to toggle the red dot badge in segments for indicating new data
        all_futures: false,
        futures: false,
        high_leverage: false,
      },

      reloadOnInit: {
        //The associated slide reloads on init() when its value is set to true
        all_futures: false,
        futures: false,
        high_leverage: false,
      },

      banner_actions: {
        notification_permission: [
          {
            text: "Enable",
            handler() {
              NativeHelper.goToNotificationSettings();
            },
          },
        ],
      },
    };
  },
  components: {
    IonButton,
    IonIcon,
    IonFabButton,
    IonFab,
    BaseLayout2,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    pinned: Pinned,
    IonSlides,
    IonSlide,
    // SignalFilterBtn,
    Banner,
    "low-leverage": LowLeverage,
    HighLeverage,
    "all-futures": AllVue,
    PinnedLocked,
    DownloadBanner
  },

  //Storing interface for methods in child components.
  childInterface: {
    all_futures: {},
    futures: {},
    high_leverage: {},
  },

  methods: {
    refresh() {
      let self = this;

      //Reload current slide
      self.update();

      //Set all others to reload on init.
      Object.keys(self.reloadOnInit).forEach((item) => {
        if (item != self.currentSegment.ref) {
          self.reloadOnInit[item] = true;
        }
      });
    },

    reactToNewFuture(type, badges = true) {
      //when 'badges' is set to true, badges appear in the associated segment.
      let self = this;

      if (type == "unknown") {
        //The circumstances surrounding this event does not allow us access to a signal type.

        //Refresh the signals page
        self.refresh();

        return;
      }

      //If current segment is all
      if (self.segment == 0) {
        //Update current segment
        self.update();
        self.newSignalInSegment(type, badges);
        self.reloadOnInit.pinned = true;
      } else if (self.segment == 3) {
        //We're in pinned
        self.update(); //Update pinned
        self.newSignalInSegment(type, badges);
        self.reloadOnInit.all_futures = true;
      } else if (type == self.currentSegment.type) {
        self.update();
        self.reloadOnInit.all_futures = true;
        self.reloadOnInit.pinned = true;
      } else {
        //Signal did not appear in either 'all' slide, or related slide.
        self.newSignalInSegment(type, badges);
        self.reloadOnInit.all_futures = true;
        self.reloadOnInit.pinned = true;
      }
    },

    //Place red badge on a segment to indicate new content
    //and set it to reload on enter.
    newSignalInSegment(type, badges = true) {
      let slide, id;

      switch (type) {
        case "futures":
          slide = "futures";
          id = "futures-segment-button-1";
          break;
        case "high_leverage":
          slide = "high_leverage";
          id = "futures-segment-button-2";
          break;
      }

      this.reloadOnInit[slide] = true;

      if (badges) {
        this.badges[slide] = true;
        let el = document.getElementById(id);
        el.scrollIntoView();
      }
    },

    setIonContent(content) {
      //Get the page's ion-content element for scroll control
      this.content = content;
    },

    listInitialized(listName) {
      this.lists[listName].initialized = true;
    },

    //Set a child component's interfaces emitted from its own child component
    getInterfaces(slide, interfaces) {
      this.$options.childInterface[slide] = interfaces;
    },

    softReset() {
      //Soft reset whatever slide is in view.
      this.$options.childInterface[this.currentSegment.ref].softReset();
    },

    hardReset() {
      //Hard reset whatever slide is in view.
      this.$options.childInterface[this.currentSegment.ref].hardReset();
    },

    update() {
      //Gets current slide to react to a new item
      this.$options.childInterface[this.currentSegment.ref].update();
    },

    //React to when the value of newSignal is decided to be set true or false from SignalList2 child component
    newSignalToggle(bool) {
      if (bool) {
        this.newSignal = true;
      } else {
        this.newSignal = false;
      }
    },

    init() {
      let self = this;

      //If there is a new signal
      if (self.store.state.newItems.future_signal) {
        let type = self.store.state.newItems.future_signal.type;
        self.reactToNewFuture(type);

        //Reset new signal status to null
        self.store.setters.setNewItem("future_signal", null);

        //Turn off badge in futures icon
        this.store.actions.triggerCompAction("new_future_seen", null);
      } else if (self.store.state.reloadOnEnter.future_signal) {
        let type = self.store.state.reloadOnEnter.future_signal.type;
        self.reactToNewFuture(type, false);

        //Reset reload on enter status for 'signal' key to false
        self.store.setters.reloadOnEnter("future_signal", null);

        //Turn off badge in futures icon
        this.store.actions.triggerCompAction("new_future_seen", null);
      }

      //Wait for next tick when segment will be available in DOM
      self.$nextTick(() => {
        //Initialize whichever segment is visible.
        self.currentSegment.comp.init();

        //If current segment has a new item badge
        if (self.badges[self.currentSegment.ref]) {
          //Remove the badge
          self.badges[self.currentSegment.ref] = false;
        }

        //If current segment has been set to reload on init()
        if (self.reloadOnInit[self.currentSegment.ref]) {
          //Reload it and set the reloadOnInit value to false.
          self.hardReset();
          self.reloadOnInit[self.currentSegment.ref] = false;
        }
      });
    },

    //Segment change event
    async segmentChanged(ev) {
      //Changes slide as well
      let slides = this.$refs.slides.$el;
      await slides.slideTo(ev.detail.value);

      this.segment = ev.detail.value;

      //Reinitialize whatever segment was just changed to.
      this.init();
    },

    slideChanged(ev) {
      ev.target;
      let self = this;

      let slides = this.$refs.slides.$el;
      slides.getActiveIndex().then((selectedIndex) => {
        self.segment = selectedIndex;
        let id = "futures-segment-button-" + self.segment;
        let el = document.getElementById(id);
        self.scrollIntoView(el);
      });
    },

    scrollIntoView(el) {
      el.scrollIntoView({
        // behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },

  computed: {
    cta_banner_actions() {
      // let self = this;
      return [
        {
          text: "Download The App",
          icon: cloudDownload,
          handler() {
            alert("opening app");
          },
        },
      ];
    },

    notificationPermission() {
      return this.store.state.notificationPermission;
    },

    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },

    //Get current segment
    currentSegment() {
      let self = this;
      let obj = {};
      switch (self.segment) {
        case "0":
        case 0:
          obj.ref = "all_futures";
          obj.type = "all";
          break;
        case "1":
        case 1:
          obj.ref = "futures";
          obj.type = "futures";
          break;
        case "2":
        case 2:
          obj.ref = "high_leverage";
          obj.type = "high_leverage";
          break;
        case "3":
        case 3:
          obj.ref = "pinned";
          obj.type = "pinned";
          break;
      }

      obj.comp = self.$refs?.[obj.ref];
      return obj;
    },

    settings() {
      return this.store.state.settings;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;
      let self = this;

      switch (action.type) {
        //There is a new spot signal
        case "new_future":
          //Figure out which spot type we have
          var type = action.data.type;
          self.reactToNewFuture(type);
          break;
        case "futures_target_update":
          //We're using the reactToNewFuture() method to react to the target update.
          self.reactToNewFuture(action.data.type, false);
          break;
      }
    },
  },

  ionViewDidEnter() {
    //In any case initialize whatever segment is in view.
    this.init();
  },

  ionViewWillLeave() {
    // this.$refs.signalList.$refs.recycler.parentViewWillLeave();
  },

  ionViewDidLeave() {
    // this.ready = false;
  },

  mounted() {
    // let self = this;
    // console.log({
    //   lang: self.lang,
    //   landObj: window.lang,
    //   langKey: self.store.state.settings.lang
    // });
  },

  created() {
    // let deviceData = this.store.state.deviceData;
    // console.log({deviceData});
  },
};
</script>

<style scoped lang="scss">
.page-container {
  transition: margin-top 0.2s, height 0.2s;

  @if $platform == "web" {
    margin-top: 94px;
    height: calc(100% - 94px);
  } @else {
    margin-top: 119px;
    height: calc(100% - 119px);
  }

  border-top-left-radius: 40px;
  background: #fafafc;
  // height: calc(100% - 86px);
  overflow: auto;
  position: relative;

  ion-slides {
    height: 100%;

    ion-slide.ion-slide {
      flex-direction: column;
    }
  }

  .parent-scroll {
    width: 100%;
    overflow: auto;
    height: 100%;
  }

  &.header-minimized {
    margin-top: 73px;
    height: calc(100% - 73px);
    .parent-scroll {
      padding-top: 76px;
    }

    ion-fab.home-ion-fab {
      top: 100px;
    }
  }
}

ion-fab.home-ion-fab {
  position: fixed;
  left: calc(50% - 28px);
  top: 156px;
  width: fit-content;
  height: fit-content;

  ion-fab-button.ion-fab-button {
    --background: #{lighten($primary-color, 70%)};
  }

  ion-icon {
    color: $primary-color;
  }
}

.no-result {
  width: 100%;
  height: 100%;
  // background: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0px;

  .image {
    width: 50%;
    margin-bottom: 50px;
  }

  p {
    position: relative;
    margin-bottom: 56px;
    margin-top: 10px;
    color: #888;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: #dedede;
      position: absolute;
      bottom: -29px;
      left: 0;
    }
  }

  h4 {
    font-weight: 800;
  }
}

ion-segment.segment {
  width: calc(100% - 40px);
  margin-left: 40px;
  margin-top: -10px;

  ion-segment-button {
    --color: var(--ion-color-secondary-contrast);
    --color-checked: var(--ion-color-secondary);
    text-transform: unset;
    font-size: calc(16px + $extra-font-size);

    ion-label {
      // font-family: "Montserrat-Black";
      transition: font-size 0.2s;

      position: relative;

      &.notify:after {
        position: absolute;
        display: block;
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #ffffff;
        top: 0;
        right: -14px;
        background: $danger-color;
      }
    }

    &.segment-button-checked {
      ion-label {
        font-size: calc(21px + $extra-font-size);
        font-weight: 900;
      }
    }
  }
}
</style>
