<template>
  <signal-list-2
    itemName="pinned-future"
    listName="pinned-futures"
    parentElementClass="pinned-futures-scroll"
    loadUrl="/pinned-futures"
    ref="List"
    :withUnwatchButton="true"
    @recyclerInstance="setRecycler"
    :noResultTitle="lang.no_contracts_liked ?? 'No contracts liked'"
    :noResultDescription="
      lang.contracts_you_like ?? 'Contracts you like appear here.'
    "
    :noResultImage="noResultImage"
  ></signal-list-2>
</template>
    
<script>
import SignalList2 from "@/views/components/cat-3/SignalList2";
import noResultImage from "@/assets/img/no-watched.png";
export default {
  inject: ["store"],

  data() {
    return {
      recycler: null,
      noResultImage,
    };
  },

  components: {
    "signal-list-2": SignalList2,
  },

  computed: {
    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;
      let self = this;

      switch (action.type) {
        //Remove or add a signal to this list
        case "toggle-watch-future":
          //Create a clone of signal before adding to list
          //in the case that 'watch' is true.
          var signal = JSON.parse(JSON.stringify(action.data.signal));

          //Now setting it's unwatchable attribute to true, won't do anything
          //to the original signal
          signal.unwatchable = true;

          var watch = action.data.watch;

          //Remove or add signal according to watch value
          if (!self.recycler.loading) {
            if (watch) {
              //Add signal to watched list
              self.recycler.items.unshift(signal);
            } else {
              //Remove signal from watched list
              self.recycler.items = self.recycler.items.filter((item) => {
                return item.id != signal.id;
              });

              //If no more signals, react.
              if (self.recycler.items.length == 0) {
                self.recycler.moreContentExists = false;
              }
            }
          }

          break;
      }
    },
  },

  methods: {
    init(callback=null) {
      this.$refs.List.init();

      if (callback) {
        callback();
      }
    },

    //Setting the recycler instance emitted from itself
    setRecycler(recycler) {
      this.recycler = recycler;
    },
  },

  mounted() {
  }
};
</script>
    
    <style>
</style>